<template>
  
</template>

<script>
export default {
    async mounted(){
        localStorage.clear();
        await this.$store.dispatch('userLogout');
        this.$router.push({ name: 'home' }).catch(()=>{});;
    }
}
</script>

<style>

</style>